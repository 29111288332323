import React, {PureComponent, ReactNode} from "react";

import teamSm from '../../../public/assets/images/repareo-team-sm.webp';
import team from '../../../public/assets/images/repareo-team.webp';

export type AboutUsProps = {
    headline: string,
    text: string
};

export class AboutUs extends PureComponent<AboutUsProps> {

    render(): ReactNode {
        const {
            headline,
            text
        } = this.props

        return (
            <div className={`aboutUs px-6 md:px-12 pb-12 md:pb-24 grid grid-cols-2 gap-x-24`}>
                <div className={'aboutUs__textBlock col-span-2 lg:col-span-1'}>
                    <h2 className={`text-2xl leading-8 font-semibold md:text-4xl md:leading-10 font-serif text-gray-900 pb-3`}>{headline}</h2>
                    <p className={'text-base leading-6 md:text-lg md:leading-7 font-normal text-gray-500 pb-8'}>{text}</p>
                </div>

                <div className={'aboutUs__ImageBlock col-span-2 lg:col-span-1 aspect-[16/10]'}>
                    <picture>
                        <source media="(max-width: 768px)" srcSet={`${teamSm.src}`}/>
                        <source media="(min-width: 769px)" srcSet={`${team.src}`}/>
                        <img
                            loading={'lazy'}
                            src={team.src}
                            alt="Das repareo Team macht Werkstattbuchung online digital und transparent"
                        />
                    </picture>
                </div>
            </div>
        );
    }
}